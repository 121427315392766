<template>
  <div>
    <v-data-table :headers="headers" :items="contracts" :search="search" :header-props="{ sortByText: 'Ordenar por:' }"
      :sort-desc="false" sort-by="immobile" hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field class="pt-0" v-model="search" append-icon="mdi-magnify" label="Pesquisar..." single-line
            hide-details></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id.slice(0, 8) }}
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ item.startDate | date }} | {{ item.endDate | date }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }"> {{ item | duration }} meses </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="statusChip[item.status].color" small outlined label>
          {{ statusChip[item.status].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :disabled="loading" @click="openContract(item)" v-if="hasPermission(32768)">
          <v-icon> mdi-eye </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    headers: [
      { text: 'ID Locação', value: 'id', sortable: true, align: 'center' },
      { text: 'Tipo', value: 'Rental.type', sortable: true, align: 'center' },
      { text: 'Início / Fim', value: 'endDate', sortable: true, align: 'center' },
      { text: 'Duração', value: 'startDate', sortable: true, align: 'center' },
      { text: 'Situação', value: 'status', sortable: true, align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    statusChip: {
      active: { text: 'Ativo', color: 'success' },
      pending: { text: 'Pendente', color: 'warning' },
      inactive: { text: 'Cancelado', color: 'error' },
      expired: { text: 'Vencido', color: 'accent' },
    },
    search: '',
  }),
  methods: {
    openContract(item) {
      this.$router.push({ name: 'rental.edit', params: { id: item.Rental.id }, hash: '#contract' })
    },

  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  filters: {
    duration(item) {
      return moment(item.endDate).diff(moment(item.startDate), 'months')
    },
    date(value) {
      return moment(value).format('DD/MM/YY')
    },
    money(value) {
      value = parseFloat(value)
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },

  },
}
</script>

<style>

</style>