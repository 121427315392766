import http from '@/http-common'

const getAll = async () => {
  return await http
    .get('/contract')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const getOne = async id => {
  return await http
    .get(`/contract/${id}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const update = async (id, data) => {
  return await http
    .put(`/contract/${id}`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  getAll,
  getOne,
  update
}
