<template>
  <v-card id="user-profile-card" :loading="loading">
    <!-- tabs -->
    <v-tabs v-model="tab" class="mt-1" show-arrows>
      <v-tab v-for="(tab, index) in loading ? [] : computedTabs" :key="index">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) in computedTabs" :key="`${tab.component}-${index}`">
        <component
          class="pa-4"
          :is="tab.component"
          :contracts="contracts"
          :loading="deepLoading"
          @refresh="getContracts"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import CONTRACT from '@/services/contract'
import ContractList from '@/components/contract/ContractList.vue'

export default {
  data: () => ({
    loading: true,
    deepLoading: false,
    contracts: [],
    tab: null,
    tabs: [
      {
        title: 'Lista',
        component: 'list',
        icon: 'mdi-text-box-check-outline',
        id: '',
      },
      {
        title: 'Aditivos Contratuais',
        permission: 524288,
        component: 'payment',
        icon: 'mdi-cash-multiple',
        id: 'update',
      },
    ],
  }),
  components: { list: ContractList },
  methods: {
    async getContracts() {
      try {
        this.loading = true
        const response = await CONTRACT.getAll()
        this.contracts = response.contracts
        this.loading = false
        this.deepLoading = false
        this.selectTab()
      } catch (e) {
        this.$toast
          .fire({
            title: e.message,
            icon: 'error',
          })
          .then(() => {
            this.$router.push('/dashboard')
          })
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace('#', '')
        let index = this.tabs.findIndex(tab => tab.id == hash)
        if (index) this.tab = index
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.getContracts()
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    computedTabs() {
      return this.tabs.filter(tab => {
        if (!tab.permission) return true
        return this.hasPermission(tab.permission)
      })
    },
  },
  watch: {
    tab() {
      if (this.tab == null) return
      let tab = this.tabs[this.tab]
      this.$router.push({ hash: tab.id }).catch(() => {})
    },
  },
}
</script>
